<template>
    <div>
        <div id="mask" class="slick-active mask_long"></div>
        <div id="tutorial_pop">
            <div class="pop_inr">
                <div class="tutorial_list ttl01">
                    <div class="con_inr">
                        <div class="tt01_txt_tutorial"><img class="tt01_txt_tutorial_img" src="/media/images/app/tt01_txt01.svg" alt="스페이스 등록으로 나만의 스페이스를 만들어보세요" /></div>
                        <div class="tt01_img_tutorial"><img class="tt01_img_tutorial_img" src="/media/images/app/tt01_img.png" alt="" /></div>

                        <!-- 닫기버튼 -->
                        <div class="close">
                            <a>
                                <span @click="disabled()">확인</span>
                            </a>
                        </div>

                        <!-- <div class="close">
              <a @click="disabled">
                <img src="/media/images/app/close_btn.png" alt="">
                <span style="color: #fff">다시보지 않기</span>
              </a>
            </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
  name: 'tutorial_main',
  methods: {
    disabled () {
      localStorage.setItem('tutorialStatusMain', 'Y');
      this.$emit('closeTutorial');
    }
  }
};
</script>

<style>
.slick-dots li button:before {
  color: #fff;
  opacity: 0.5 !important;
  margin-right: 0;
}
.slick-dots li.slick-active button:before {
  color: #fff;
  opacity: 1 !important;
}
.slick-dots li {
  top: -50%;
  left: -5%;
  width: 5px;
  height: 10px;
}
.ttl01 .con_inr {
  margin-top: 0%;
}
.tutorial_list .close {
  position: absolute;
  bottom: 10%;
  right: 0;
  width: 100%;
  text-align: right;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tutorial_list .close a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
}
.tutorial_list .close a img {
  display: inline-block;
  width: 2.222vw;
  min-width: 8px;
  max-width: 10px;
}
.tutorial_list .close a span {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.005em;
  padding: 14px 40px;
  border: 1.6px solid #888;
  border-radius: 100px;
}
</style>
<style scoped>
.tt01_txt_tutorial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -250%);
  width: 205px;
}

.tt01_txt_tutorial_img {
  width: 100%;
}

.tt01_img_tutorial {
  width: 280px;
  height: 280px;
  position: absolute;
  top: 59%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tt01_img_tutorial_img {
  width: 100%;
  height: 100%;
}
</style>
<style src="@/assets/css/mobile_style.css"></style>
